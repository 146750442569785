.form-field{
    display: flex;
    flex-direction: column;
    padding:3px 0px;

    &__label{
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #333C44;

        &__required{
            color: #D51B15;
        }
    }
    &__input{
        border: 1px solid #F4F5F6;
        height: 33.5px;
        background-color: #F4F5F6;
        text-indent:10px;
        border-radius: 5px;
        font-size: 14px;

        &--error{
            border: 1px solid #D51B15;
        }

        &--disabled{
            background-color: #F9F9F9;

            &:hover{
                cursor:not-allowed;
            }
        }
    }
    &__input:focus{
        outline: 2px solid #3084B7;
    }
}