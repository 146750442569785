.date-picker-inline {
    border: 0px solid coral;
    display: flex;
    padding: 3px 10px;
    // align-items: center;



    &__label {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #333C44;
        


        &__required {
            color: #D51B15;
        }
    }

    &__input {
        margin-left: 10px;
        border: 1px solid #F4F5F6;
        height: 22px;
        max-width: 90px;
        background-color: #F4F5F6;
        text-indent: 10px;
        border-radius: 7px;
        font-size: 13px;

        &:focus {
            outline: 0px solid #3084B7;
        }

        &--error {
            border: 1px solid #D51B15;
        }
        
        &--disabled{
            background-color: #F9F9F9;

            &:hover{
                cursor:not-allowed;
            }
        }
    }

    &__date-view {
        width: 100%;
    }
}

.react-datepicker {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    background-color: #FFF;
    color: #000;
    border: 0px;
    box-shadow: 0px 0px 10px #D1D1D1;
    border-radius: 10px;

}

.react-datepicker__header {
    background-color: #FFF;
    border-bottom: 1px solid #E9E9E9;
}

.react-datepicker__today-button {
    background: #FFF;
    border-top: 1px solid #E9E9E9;
}