.report-header {
    border: 0px solid coral;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;

    &--sticky {
        background-color: #FFF;
        box-shadow: 0px 0px 4px #E1E1E1;
    }

    &__tools {
        display: flex;
        gap: 8px;

        &__actions {
            display: flex;
            gap: 8px;
            justify-content: end;

            @media screen and (max-width: 991px) {
                width: 100%;
                border: 0px solid coral;
            }

            &__form{
                display: flex;
                gap: 8px;
            }
        }

        @media screen and (max-width: 991px) {
            padding: 0 0px;
            border: 0px solid coral;
        }
    }

    &__spinner{
        margin-right: 15px;
    }    

    @media screen and (max-width: 991px) {
        z-index: 2;
    }

    @media (min-width: 1200px) {
        max-width: 1290px !important;
    }
}

// .date-range-picker-wrapper{
//     position: relative;
//     z-index: 1;    
// }

// .date-range-picker{
//     position: absolute;
//     right:10px;
//     top: 35px;
//     border: 1px solid #E1E1E1;

//     @media screen and (max-width: 991px) {
//         left: -112px;
//     }
// }

// .date-range-picker-actions{
//     width: 100%;
//     border: 0px solid coral;
//     display: flex;
//     gap: 10px;
//     background-color: #FFF;
    
//     padding: 5px;
//     padding-left: 15px;

//     @media screen and (max-width: 991px) {
//         width: 225px;
//     }
// }

// .rdrCalendarWrapper{
    
//     @media screen and (max-width: 991px) {
//         display: none !important;
//     }
// }