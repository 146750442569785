.form-table-select {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    border: 0px solid coral;

    &__label {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #333C44;
        text-transform: capitalize;

        &__required {
            color: #D51B15;
        }
    }

    &__select {

        &--error{
            border: 1px solid #D51B15;
        }
    }
}