.report-table-container {

    &__spinner {
        text-align: center;
    }

    &__date-range {
        font-family: "Robrto", sans-serif;
        font-size: 13px;
        color: #666;
        margin-bottom: 10px;
        margin-left: 5px;
    }

}

.scrollable-report {
    max-height: 412px;
    overflow-y: scroll;

    @media screen and (max-width: 991px) {
        min-height: calc(100vh - 110px);
        padding-right: 15px;
    }
}

.infinite-scroll {
    overflow: visible !important;

    &__spinner {
        text-align: center;
    }
}

.report-table {
    border: 0px solid coral;
    width: 100%;
    border-collapse: collapse;
    // overflow-x: hidden;

    &__header {
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 1px #666;


        &__item {
            font-family: "Roboto", sans-serif;
            color: #555;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 10px;
            padding-top: 5px;
            padding-left: 5px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            user-select: none;

            &__sort {
                margin-left: 7px;
                display: inline-block;
                border: 0px solid coral;

                &__icon {
                    border: 0px solid coral;
                    margin-top: -3px;
                    ;
                }
            }

            &__resizer {
                position: absolute;
                border: 1px solid #EEEEEE;
                background-color: #EEEEEE;
                right: 5px;
                height: 25px;
                width: 1px;
                top: 0;
                touch-action: none;
            }

            &:last-child>&__resizer {
                display: none;
            }

            &:last-child {
                position: relative;
                z-index: 2;
            }

            &:nth-child(1) {
                background-color: #FFFFFF;
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &__body {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #1F2747;
        border: 0px solid coral;
        display: block;
        margin-bottom: 15px;


        &__row {
            border-bottom: 1px solid #F4F5F6;

            &__cell {
                padding: 10px 0px;
                padding-left: 5px;
                border: 0px solid #F9FAFA;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &__header {
                    display: none;

                    @media screen and (max-width: 991px) {
                        display: block;
                        font-family: "Roboto", sans-serif;
                        font-weight: 500;
                        text-align: left;
                        border: 0px solid coral;
                    }
                }

                &__data {
                    display: inline;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                }

                &:nth-child(1) {
                    font-weight: 500;
                    color: #1F2747;
                    background-color: #FFFFFF;

                    @media screen and (max-width: 991px) {
                        font-weight: 400;
                        background-color: transparent;
                    }
                }

                @media screen and (max-width: 991px) {
                    display: block;
                    min-width: 100%;
                    border-bottom: 1px solid #EEEEEE;
                }
            }


            &:hover {
                background-color: #F9FAFA;
            }

            &:hover &__cell:nth-child(1) {
                background-color: #F9FAFA;
            }

            &:nth-child(even) {
                // background-color: #F9FAFA;

                @media screen and (max-width: 991px) {
                    background-color: #F9FAFA;
                }
            }

            @media screen and (max-width: 991px) {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                margin-bottom: 15px;
                border-bottom: 0px;
                padding: 5px;
            }
        }

        @media screen and (max-width: 991px) {
            display: block;
            width: 100% !important;
        }
    }

    &__footer {
        position: sticky;
        bottom: -1px;
        z-index: 1;
        background-color: #FFFFFF;
        box-shadow: 0px -1px 1px #E9E9E9;

        &__item {
            font-family: "Roboto", sans-serif;
            color: #555;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 0px;
            padding-top: 15px;
            padding-left: 5px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;

            &__sort {
                margin-left: 7px;
                display: inline-block;
                border: 0px solid coral;

                &__icon {
                    border: 0px solid coral;
                    margin-top: -3px;
                    ;
                }
            }

            &__resizer {
                position: absolute;
                border: 1px solid #EEEEEE;
                background-color: #EEEEEE;
                right: 5px;
                height: 25px;
                width: 1px;
                top: 0;
                touch-action: none;
            }

            &:last-child>&__resizer {
                display: none;
            }

            &:last-child {
                position: relative;
                z-index: 2;
            }

            &:nth-child(1) {
                background-color: #FFFFFF;
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    @media screen and (max-width: 991px) {
        display: block;
        width: 100% !important;
    }
}