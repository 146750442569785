.report{
    position: relative;
    max-width: 1290px;
    margin: 0px auto;

    &__nav-body{
        border: 0px solid coral;
        display: flex;
        padding: 0px 20px;

        &__nav{ 
            width: 15%;

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }

        &__body{
            width: 85%;

            &--full{
                width: 100%;
            }

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 991px) {
            flex-direction: column;
        }
    }
}