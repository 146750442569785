.home-nav{
    width: 100%;
    padding: 20px 20px;
    padding-bottom: 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(25, 39, 52, .13);
    margin-top: 40px;

    &__single {
        padding: 20px 20px;
    }

    &__chart {
        padding: 0px !important;
    }
}