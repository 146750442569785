.table-inline-action-button {
    width: 22px;
    height: 20px;
    background-color: #FFFFFF;
    border: 0px solid #C1C9D2;
    border-radius: 7px;
    box-shadow: 0 0 4px #C1C9D2;
    display: flex;
    justify-content: center;
    align-items: center;
}