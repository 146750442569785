.help-body-item {

    &__title {
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        font-weight: 500;
        color: #4A4A4A;
        max-width: 750px;
    }

    &__body {
        margin: 13px 0px;
        max-width:950px;
        border: 0px solid coral;

        &__paragrph {
            font-family: "Roboto", sans-serif;
            font-size: 15px;
            max-width: 750px;
            color: #4A4A4A;
        }

        &__list {
            max-width: 750px;
            list-style-position: inside;
            margin: 15px 0px;
            color: #4A4A4A;

            &__item {
                font-family: "Roboto", sans-serif;
                font-size: 15px;
            }
        }

        &__img{
            width: 100%;
        }
    }
}