.form-header {
    border: 0px solid coral;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 20px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;

    &--sticky {
        background-color: #FFF;
        box-shadow: 0px 0px 4px #E1E1E1;
    }

    &__tools {
        display: flex;
        gap: 8px;

        &__actions {
            display: flex;
            gap: 8px;
            justify-content: end;

            @media screen and (max-width: 991px) {
                width: 100%;
                border: 0px solid coral;
            }
        }

        @media screen and (max-width: 991px) {
            padding: 0 0px;
            border: 0px solid coral;
        }
    }

    &__spinner{
        margin-right: 15px;
    }    

    @media screen and (max-width: 991px) {
        z-index: 2;
    }

    @media (min-width: 1200px) {
        max-width: 1290px !important;
    }
}