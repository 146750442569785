.nav-item {
    position: relative;
    top: 0px;
    left: 0px;
    border: 0px solid coral;

    &__link {
        width: 100%;
        font-family: 'Roboto', sans-serif;
        border: 0px;
        background-color: transparent;
        padding: 10px 18px;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        color: #334551;
        // letter-spacing: px;
        border-radius: 5px;
        align-items: center;
        justify-content: space-between;
    }

    &__link:hover {
        background-color: #F4F5F6;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
    }

    &__icon {
        margin-left: 8px;
        width: 8px;
        pointer-events: none;
        transform: rotate(0deg);
        border: 0px solid coral;;
    }
}