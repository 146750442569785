.detail-body-section-header {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__title-collapse {
        cursor: pointer;
        user-select: none;
        border: 0px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;

        &__title{
            font-family: "Roboto", sans-serif;
            font-size: 15px;
            font-weight: 500;
            margin-right:10px;
            color: #1F2747;

            &--print-font{
                font-size: 15px
            }
        }

        &__icon{

            &--up{
                transform: rotate(180deg);
            }
        }
        
    }
}