.general-modal__footer{
    border: 0px solid coral;
    background-color: #F9FAFA ;
    width: 100%;
    height: 53px;
    border-radius: 0 0 15px 15px;
    padding:10px;
    padding-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    position: absolute;
    bottom: 0px;
    left:0px;
    right:0px;
    z-index: 4000px;

    @media screen and (max-width: 991px){
        border-radius: 0px;
        padding-right: 10px;
    }

    &__spinner{
        margin-right: 15px;
    }
}