.form-side-bar {
    border: 0px solid coral;
    padding-right: 10px;

    &__nav{
        list-style-type: none;
        border: 0px solid coral;
        
        &__item{
            margin-bottom: 2px;

            &__button{
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                color: #505A62;
                padding: 7px 15px;
                width: 100%;
                text-align: left;
                border: 0px;
                background-color: transparent;

                &:hover{
                    background-color: #EBEEF0;
                    border-radius: 7px;
                    cursor: pointer;
                }

                &--active {
                    background-color: #EBEEF0;
                    border-radius: 7px;
                }
            }
        }

        @media screen and (max-width: 991px) {
            display: flex;
            gap: 10px;
        }
    }

}