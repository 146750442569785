.list-global-filter{
    background-color: #FFFFFF;
    height: 30px;
    border-radius: 7px;
    display: flex;
    padding: 0px 10px;
    width: 100%;
    
    &__icon{
        width: 15px;
        pointer-events: none;
    }

    &__field{
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        text-indent: 10px;
        border: 0px;
        background-color: #FFFFFF;
        height: 30px;
        width: 100%;

        &:focus{
            outline: 0px;
        }
    }
}