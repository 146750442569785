.log-in{
    width: 100%;
    height: 95vh;
    background-color:#F5F6FA;
   
    &__wraper{
        height: 95vh;
    }
    &__logo-img-wraper{
        padding: 20px 0;
    }
    &__logo-img{
       margin: 0 auto;
       display: block;
    }
}