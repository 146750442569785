.log-in-form-group{
  margin:0 22px;

  &__label{
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #707070;
    margin-top: 10px;
  }
  &__input{
      border: 1px solid #E6EBF1;
      width: 100%;
      height: 53px;
      text-indent: 10px;
      border-radius: 4px;
      &:focus{
        outline: none;
        border:1px solid #0074E3;
        box-shadow: 0 0 3px #0074E3;
      }   
  }
  &--initial-focus{
    border:1px solid #0074E3;
    box-shadow: 0 0 3px #0074E3;
  }
}