.form-table-text-field{
    display: flex;
    flex-direction: column;
    padding:0px 0px;

    &__label{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #333C44;

        &__required{
            color: #D51B15;
        }
    }
    &__input{
        border: 1px solid #F4F5F6;
        height: 36px;
        background-color: #F4F5F6;
        text-indent:10px;
        border-radius: 0px;
        font-size: 14px;
        margin: 1px;
     

        &--error{
            border: 1px solid #D51B15;
        }

        &--right{
            text-align: right;
            padding-right: 10px;
        }
    }
    &__input:focus{
        outline: 2px solid #3084B7;
    }
}