.form-table-button{
    width: auto;
    height: 30px;
    padding: 0px 13px;
    color: #1F272E;
    background-color: #F4F5F6;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    border:1px solid #F4F5F6;
    border-radius: 7px;

    &:hover{
        background-color: #C0C6CC;
        border:1px solid #C0C6CC;
    }

    &__icon{
        margin-right:5px;
        width: 11px;
        margin-top:-1px;
    }

    &--disabled{
        background-color: #D8DDE6 !important;
        color: #1F272E;
    }
    &--inverted{
        background-color: #FFF;
        color: #687178;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 0px 3px #999;
    }

    &--left-margin{
        margin-left: 10px;
    }
}