.date-range-picker-wrapper{
    position: relative;
    z-index: 1;    
}

.date-range-picker{
    position: absolute;
    right:10px;
    top: 35px;
    border: 1px solid #E1E1E1;
}

.date-range-picker-actions{
    width: 100%;
    border: 0px solid coral;
    display: flex;
    gap: 10px;
    background-color: #FFF;
    
    padding: 5px;
    padding-left: 15px;

    @media screen and (max-width: 991px) {
        width: 225px;
    }
}

.rdrCalendarWrapper{
    
    @media screen and (max-width: 991px) {
        display: none !important;
    }
}